const IS_WITH_PROD = import.meta.env.MODE === 'dev-with-prod'
const IS_WITH_STAGING = import.meta.env.MODE === 'dev-with-staging'

type EnvName = 'production' | 'staging' | 'development' | 'test'

function getEnvFromHostname(): EnvName {
  if (window.location.hostname.includes('.dev')) {
    return 'staging'
  }
  if (window.location.hostname.includes('.local')) {
    return 'development'
  }
  return 'production'
}

export type Env = {
  VITE_NODE_ENV: EnvName
  VITE_COMMIT_HASH: string
  VITE_STRIPE_PUBLISHABLE_KEY: string
  VITE_SEGMENT_KEY: string
  VITE_GRAPHQL_API: string
  VITE_NAVIGATOR_URL: string
  VITE_SC_ATTR: string
  VITE_X_BUFFER_CLIENT_ID: string
  VITE_CUSTOMERIO_SITE_ID: string
  VITE_CUSTOMERIO_API_KEY: string
}

const COMMON_VARS: Pick<
  Env,
  'VITE_COMMIT_HASH' | 'VITE_SC_ATTR' | 'VITE_X_BUFFER_CLIENT_ID'
> = {
  VITE_COMMIT_HASH: `${import.meta.env.VITE_COMMIT_HASH}`,
  VITE_SC_ATTR: 'analyze-styles',
  VITE_X_BUFFER_CLIENT_ID: 'webapp-analyze',
}

const PRODUCTION_VARS: Env = {
  ...COMMON_VARS,
  VITE_NODE_ENV: 'production',
  VITE_STRIPE_PUBLISHABLE_KEY: 'pk_qOmHaWApLCX5OoeWKQ3NrArhoyWEi',
  VITE_SEGMENT_KEY: '5gcu59p2cjBhMBHic63lUQczOrQMfMxt',
  VITE_GRAPHQL_API: 'https://graph.buffer.com',
  VITE_NAVIGATOR_URL: 'https://components.buffer.com/navigator.js',
  VITE_CUSTOMERIO_SITE_ID: 'f902d427753d24b6e6df',
  VITE_CUSTOMERIO_API_KEY: 'f902d427753d24b6e6df:3ab31a41d059272394c8',
}

const STAGING_VARS: Env = {
  ...COMMON_VARS,
  VITE_NODE_ENV: 'staging',
  VITE_STRIPE_PUBLISHABLE_KEY: 'pk_dGKqAIFsUQonSYGPBM9Rek71IHOcL',
  VITE_SEGMENT_KEY: 'YDcr43WV9yiBxqc1dWAgTSROLBZRa2hX',
  VITE_GRAPHQL_API: 'https://graph.dev.buffer.com',
  VITE_NAVIGATOR_URL: 'https://components.buffer.com/navigator-staging.js',
  VITE_CUSTOMERIO_SITE_ID: '7c16d71a254f8ac07907',
  VITE_CUSTOMERIO_API_KEY: '7c16d71a254f8ac07907:9c61e8f8717a504dba4e',
}

const DEVELOPMENT_VARS: Env = {
  ...STAGING_VARS,
  ...COMMON_VARS,
  VITE_NODE_ENV: 'development',
  VITE_GRAPHQL_API: 'https://graph.local.buffer.com',
  VITE_NAVIGATOR_URL: 'https://components.buffer.com/navigator.js',
  VITE_CUSTOMERIO_SITE_ID: '82d38857f6dae390778e',
  VITE_CUSTOMERIO_API_KEY: '82d38857f6dae390778e:8e51af7d0a8f1d08e737',
}

function selectEnv(): Env {
  const envName = getEnvFromHostname()

  if (IS_WITH_PROD) {
    return {
      ...PRODUCTION_VARS,
      VITE_NODE_ENV: 'development',
    }
  }
  if (IS_WITH_STAGING) {
    return {
      ...STAGING_VARS,
      VITE_NODE_ENV: 'development',
    }
  }
  if (envName === 'development') {
    return DEVELOPMENT_VARS
  }
  if (envName === 'staging') {
    return STAGING_VARS
  }

  return PRODUCTION_VARS
}

console.log('env', selectEnv())

export const env = selectEnv()
